import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <header className="post">
      <h1>
        <Link to="/">Seinpost</Link>
      </h1>
    </header>
    <main>
      <div className="text">
        <h1>Pagina niet gevonden</h1>
        <p>
          De pagina die je zocht is niet gevonden. Op de{' '}
          <Link to="/">index</Link> vind je al onze berichten terug.
        </p>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
